import { createSlice } from '@reduxjs/toolkit'

interface FeatureFlag {
  env?: string[]
  users?: string[]
}

interface FeatureFlagState {
  [featureName: string]: FeatureFlag
}

const initialState: FeatureFlagState = {
  isDtcEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNftEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNewLoginEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNewExportsCSVEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNewHQTransactEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isMultiLinePaymentEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isBankFeedEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isXeroCertificationEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isDraftTransactionsEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isQueueTransactionsEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isSpotBalanceEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isAnnotationEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  }
}

export const featureFlagSlice = createSlice({
  name: 'feature-flags',
  initialState,
  reducers: {}
})

export default featureFlagSlice.reducer
