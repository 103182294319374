import Image from 'next/image'
import HqLogo from '@/public/svg/Headquarters.svg'

const LoaderHQ = () => (
  <div className="flex flex-col justify-center items-center">
    <Image src={HqLogo} alt="logo" width={400} height={70} />
    <div className="flex gap-6 mt-6">
      <div className="w-4 h-4 rounded-full bg-grey-900 animate-bounce" />
      <div className="w-4 h-4 rounded-full bg-grey-900 animate-bounce" />
      <div className="w-4 h-4 rounded-full bg-grey-900 animate-bounce" />
    </div>
  </div>
)

export default LoaderHQ
