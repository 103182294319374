import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const CopyIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 12,
  stroke = '#99A4AF',
  strokeWidth = 1.5,
  width = 12
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className="icon-svg"
  >
    <path
      className="icon-path"
      d="M8 8V9.4C8 9.96005 8 10.2401 7.89101 10.454C7.79513 10.6422 7.64215 10.7951 7.45399 10.891C7.24008 11 6.96005 11 6.4 11H2.6C2.03995 11 1.75992 11 1.54601 10.891C1.35785 10.7951 1.20487 10.6422 1.10899 10.454C1 10.2401 1 9.96005 1 9.4V5.6C1 5.03995 1 4.75992 1.10899 4.54601C1.20487 4.35785 1.35785 4.20487 1.54601 4.10899C1.75992 4 2.03995 4 2.6 4H4M5.6 8H9.4C9.96005 8 10.2401 8 10.454 7.89101C10.6422 7.79513 10.7951 7.64215 10.891 7.45399C11 7.24008 11 6.96005 11 6.4V2.6C11 2.03995 11 1.75992 10.891 1.54601C10.7951 1.35785 10.6422 1.20487 10.454 1.10899C10.2401 1 9.96005 1 9.4 1H5.6C5.03995 1 4.75992 1 4.54601 1.10899C4.35785 1.20487 4.20487 1.35785 4.10899 1.54601C4 1.75992 4 2.03995 4 2.6V6.4C4 6.96005 4 7.24008 4.10899 7.45399C4.20487 7.64215 4.35785 7.79513 4.54601 7.89101C4.75992 8 5.03995 8 5.6 8Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CopyIcon
