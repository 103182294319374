import Image from 'next/image'
import { FC, useEffect, useState } from 'react'
import HqLogo from '@/public/svg/logos/hq-logo-1.svg'
import ProfilePopup from '@/components/Sidebar-v2/components/ProfilePopup'
import JournalEntryDialogue from './JournalEntryDialogue'
import { useOrganizationIntegrationsQuery } from '@/api-v2/merge-api'
import { useOrganizationId } from '@/utils/getOrganizationId'
import CountryCurrency from './CountryCurrency'
import { useAppSelector } from '@/state'
import { orgSettingsSelector } from '@/slice/orgSettings/orgSettings-slice'
import { accountingIntegrationSelector } from '@/slice/org-integration/org-integration-selector'
import ExportsDialog from './ExportsDialog'
import { selectFeatureState } from '@/slice/feature-flags/feature-flag-selectors'

export interface IPropsTopBar {
  user: {
    firstName: string
    lastName: string
    loginCredentials: string
    role: string
  }
  onClickLogout: () => void
  onClickNavigateToProfile: () => void
  isLoading?: boolean
  refetch?: () => void
}

const TopAppBar: FC<IPropsTopBar> = ({ user, onClickLogout, onClickNavigateToProfile, isLoading = false, refetch }) => {
  const organizationId = useOrganizationId()
  const accountingIntegration = useAppSelector(accountingIntegrationSelector)
  const isNewExportsCSVEnabled = useAppSelector((state) => selectFeatureState(state, 'isNewExportsCSVEnabled'))
  const [isRunning, setIsRunning] = useState(false)
  useEffect(() => {
    if (!isRunning) {
      refetch()
    }
  }, [isRunning])

  const toggleRunning = (state) => {
    setIsRunning(state)
  }

  const { fiatCurrency: fiatCurrencySetting, country: countrySetting } = useAppSelector(orgSettingsSelector)

  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false)

  const handleOnClickprofileDropdown = () => setIsProfilePopupOpen(!isProfilePopupOpen)
  return (
    <div className="flex justify-between items-center px-4 h-[68px] min-w-[1024px] w-full z-10 ">
      <div>
        <Image src={HqLogo} height={48} width={168} />
      </div>
      <div className="flex flex-row gap-4 items-center">
        {countrySetting?.iso && (
          <CountryCurrency
            country={countrySetting}
            currency={fiatCurrencySetting?.code}
            organizationId={organizationId}
          />
        )}
        {!isLoading &&
          !isNewExportsCSVEnabled &&
          accountingIntegration &&
          accountingIntegration?.status === 'completed' && (
            <JournalEntryDialogue accountingIntegration={accountingIntegration} />
          )}
        {(!isLoading || accountingIntegration) && isNewExportsCSVEnabled && (
          <ExportsDialog accountingIntegration={accountingIntegration} toggleRunning={toggleRunning} />
        )}
        <ProfilePopup
          isOpen={isProfilePopupOpen}
          onClick={handleOnClickprofileDropdown}
          user={user}
          onClickLogout={onClickLogout}
          handleNavigateToProfile={onClickNavigateToProfile}
        />
      </div>
    </div>
  )
}
export default TopAppBar
