// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const shouldSendToSentry = 
  ['staging','production'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)

Sentry.init({
  dsn: SENTRY_DSN || 'https://e21a7ccc5ddd4261b0faccf57b93ad6c@o1214724.ingest.sentry.io/6360447', 
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: shouldSendToSentry 
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
