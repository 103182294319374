import {  createSlice } from '@reduxjs/toolkit'
import { cryptoCurrenciesApi } from '@/api-v2/cryptocurrencies'
import { pricesApi } from '@/api-v2/pricing-api'

const initialState: any = {
  allCryptoCurrencies: [],
  verifiedCryptoCurrencies: [],
  tokenPrices: {}
}

export const cryptocurrencySlice = createSlice({
  name: 'cryptocurrency-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        // @ts-ignore
        cryptoCurrenciesApi.endpoints.getCryptoCurrencies.matchFulfilled,
        (state, { payload }) => {
          state.allCryptoCurrencies = payload?.data
        }
      )
      .addMatcher(
        // @ts-ignore
        cryptoCurrenciesApi.endpoints.getVerifiedCryptoCurrencies.matchFulfilled,
        (state, { payload }) => {
          state.verifiedCryptoCurrencies = payload?.data
        }
      )
      .addMatcher(
        // @ts-ignore
        pricesApi.endpoints.getTokenPrice.matchFulfilled,
        (state, { type, payload, meta }) => {
          const cryptocurrencyId = meta?.arg?.originalArgs?.params?.cryptocurrencyId ?? null
          if (cryptocurrencyId) {
            state.tokenPrices[cryptocurrencyId] = payload?.data
          }
        }
      )
  }
})