import React from 'react'
import { SideNavBar, ISideBarProps } from '@/components-v2/organisms/Sidebar'
import TopAppBar, { IPropsTopBar } from '@/components-v2/molecules/Topbar'
import { LoaderHQ } from '@/components-v2/LoaderHQ'

interface ChildProps {
  children: React.ReactNode
  bannerDisplayed?: boolean
  isLoading?: boolean
}
interface ListViewWithChildren extends React.FC<ChildProps> {
  Header: React.FC<IPropsTopBar>
  Body: BodyWithChildren
}

interface BodyWithChildren extends React.FC<ChildProps> {
  Sidebar: React.FC<ISideBarProps>
  Content: React.FC<ChildProps>
}

/* Component Level: Core Wrapper */
const BaseTemplate: ListViewWithChildren = ({ children, isLoading }) =>
  isLoading ? (
    <div className="h-screen flex justify-center">
      <LoaderHQ />
    </div>
  ) : (
    <div className="bg-grey-100 h-screen ">{children}</div>
  )

/* Component Level: Inner Core Wrapper - To Handle Sidebar / Content Position */
export const Body: BodyWithChildren = ({ children, bannerDisplayed }) => (
  // Calculate the header into giving the height for content
  <div className={`flex flex-row ${bannerDisplayed ? 'h-[calc(100vh-152px)]' : 'h-[calc(100vh-84px)]'} pb-4`}>
    {children}
  </div>
)

/* Component Level: White Card Content */
export const Content: React.FC<ChildProps> = ({ children, bannerDisplayed = false }) => (
  <div
    className={`bg-white w-full min-w-[1024px] ml-4 mr-4 ${
      bannerDisplayed ? 'h-[calc(100vh-152px)]' : 'h-[calc(100vh-84px)]'
    } rounded-xl font-inter flex flex-col overflow-y-auto scrollbar`}
  >
    {children}
  </div>
)

BaseTemplate.Body = Body
BaseTemplate.Header = TopAppBar

Body.Sidebar = SideNavBar
Body.Content = Content

export default BaseTemplate
