import { api } from '@/api-v2'
import { AppState } from '@/state'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { IContactProvider, IContacts } from './contacts.types'

interface IContactState {
  contacts: IContacts[]
  contactProviders: IContactProvider[]
}

const initialState: IContactState = {
  contacts: [],
  contactProviders: []
}

export const contactsSlice = createSlice({
  name: 'contact-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      // @ts-ignore
      api.endpoints.getContacts.matchFulfilled,
      (state, { payload }) => {
        state.contacts = payload
      }
    )
    builder.addMatcher(
      // @ts-ignore
      api.endpoints.getContactProvider.matchFulfilled,
      (state, { payload }) => {
        state.contactProviders = payload
      }
    )
  }
})

const selectSelf = (state: AppState) => state.contacts

export const contactsSelector = createSelector(selectSelf, (state) => state.contacts?.items)
export const contactProvidersSelector = createSelector(selectSelf, (state) => state.contactProviders)
