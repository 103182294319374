export const TOKENS_URL = [
  {
    name: 'ETH',
    logoUrl: '/svg/ETH.svg',
    decimal: 18
  },
  {
    name: 'XSGD',
    logoUrl: '/svg/XSGD.svg',
    decimal: 6
  },
  {
    name: 'MATIC',
    logoUrl: '/svg/matic_new.png',
    decimal: 18
  },
  {
    name: 'USDC',
    logoUrl: '/svg/USDC.svg',
    decimal: 6
  },
  {
    name: 'XIDR',
    logoUrl: '/svg/XIDR.svg',
    decimal: 6
  },
  {
    name: 'BNB',
    logoUrl: '/svg/BNB.svg',
    decimal: 18
  },
  {
    name: 'DAI',
    logoUrl: '/svg/DAI.svg',
    decimal: 18
  },
  {
    name: 'USDT',
    logoUrl: '/svg/NewUSDT.svg',
    decimal: 18
  },
  {
    name: 'BLUSGD',
    logoUrl: '/svg/tokens/blu-sgd.png',
    decimal: 18
  },
  {
    name: 'MNT',
    logoUrl: '/svg/mantle.png',
    decimal: 18
  },
  {
    name: 'SAND',
    logoUrl: '/svg/sand.png',
    decimal: 18
  },
  {
    name: 'SIMP',
    logoUrl: '/svg/socol.png',
    decimal: 18
  }
]
